import styled from 'styled-components';

export const BannerContainer = styled.div`
  background: #3d0556;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #661988;
`;

export const BannerStyle = styled.div`
  width: 780px;
  height: auto;
  margin: 0 auto;
  padding: 55px 0px;
  text-align: center;
  @media (max-width: 767px) {
    padding: 55px 35px;
    width: 100%;
    height: auto;
  }
`;

export const ImageStyle = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30px;
  border: 0;
  vertical-align: top;
`;

export const ParaText = styled.p`
  margin: 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 46px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  padding: 0;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
`;

export const HeaderH1 = styled.h1`
  margin: 0 !important;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const HeaderH4 = styled.h4`
  margin: 0 !important;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 28px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-size: 36px;
    line-height: 46px;
  }
`;