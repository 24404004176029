import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import * as Styled from './slpBannerStyle';
import { BLOCKS } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';

const SlpBanner = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeaderH4>{children}</Styled.HeaderH4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.BannerContainer>
      <Styled.BannerStyle>
        {sectionData?.contentDetails && (
          <>
            {documentToReactComponents(
              JSON.parse(sectionData?.contentDetails.raw),
              optionsMainStyle
            )}
          </>
        )}
        <div style={{ clear: 'both' }}></div>
      </Styled.BannerStyle>
    </Styled.BannerContainer>
  );
};

export default SlpBanner;
